import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body{
    font-size: 10px;
    scroll-behavior: smooth;
  }

  body {
    width: 100%;
    height: auto;
    font-size: 10px;
    min-height: 100vh;
    font-family: 'Inter', sans-serif;
    background-image: linear-gradient(180deg, #0F1122 0%, #15142C 100%);
  }

  #chat-widget-container{
    display: none !important;
  }

  .content{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;
