import wp0 from "./wp0.png";
import wp1 from "./wp1.png";
import wp2 from "./wp2.png";
import wp3 from "./wp3.png";
import wp4 from "./wp4.png";
import wp13 from "./wp13.png";
import logo from "./logo.png";
import menu from "./menu.png";
import close from "./close.png";
import part2 from "./part2.png";
import lpLogo from "./LP_LOGO.png";
import holders from "./holders.png";
import poocoin from "./poocoin.png";
import uniswap from "./uniswap.jpg";
import dexview from "./dexview.png";
import dextools from "./dextools.png";
import pancakeswap from "./pancakeswap.png";
import logoPatrocinado1 from "./logo_patrocinado1.png";
import logoPatrocinado2 from "./logo_patrocinado2.png";
import logoPatrocinado3 from "./logo_patrocinado3.png";

interface IImage<TValue> {
  [id: string]: TValue;
}

const images: IImage<string> = {
  wp0,
  wp1,
  wp2,
  wp3,
  wp4,
  wp13,
  logo,
  menu,
  part2,
  close,
  lpLogo,
  holders,
  poocoin,
  dexview,
  uniswap,
  dextools,
  pancakeswap,
  logoPatrocinado1,
  logoPatrocinado2,
  logoPatrocinado3,
};

export const getImage = (id: string) => images[id];

export default images;
