import images from "../../../assets/img";
import * as S from "./FooterStyled";

const Footer = () => {
  return (
    <S.FooterContainer>
      <S.Img src={images.logo} alt="Logo cyber pirates" />
      <S.ContainerLinks>
        <S.Link
          href="https://cyberpiratetoken.gitbook.io/cyber-pirate-token-cpt/tokenomics"
          target="_blank"
        >
          <S.Text>TOKENOMICS</S.Text>
        </S.Link>
        <S.Link
          href="https://cyberpiratetoken.gitbook.io/cyber-pirate-token-cpt/cyber-roadmap"
          target="_blank"
        >
          <S.Text>ROADMAP</S.Text>
        </S.Link>
        <S.Text>CYBER SHIP</S.Text>
        <S.Link
          href="https://cyberpiratetoken.gitbook.io/cyber-pirate-token-cpt/cpt-pool"
          target="_blank"
        >
          <S.Text>LP EARN</S.Text>
        </S.Link>
        <S.Link
          href="https://cyberpiratetoken.gitbook.io/cyber-pirate-token-cpt/"
          target="_blank"
        >
          <S.Text>WHITEPAPER</S.Text>
        </S.Link>
      </S.ContainerLinks>
      <S.ContainerLinks>
        <S.Text>Join our Community</S.Text>
        <S.Link href="https://t.me/CyberPiratestoken" target="_blank">
          <S.Text>Telegram</S.Text>
        </S.Link>
        <S.Link href="https://twitter.com/CyberPiratesT" target="_blank">
          <S.Text>Twitter</S.Text>
        </S.Link>
      </S.ContainerLinks>
      <S.ContainerLinks end>
        <S.Text light>Contact us</S.Text>
        <S.Text light>contact@cyberpirates.AI</S.Text>
        <S.Text light>marketing@cyberpirates.AI</S.Text>
      </S.ContainerLinks>
    </S.FooterContainer>
  );
};

export default Footer;
