import styled, { css } from "styled-components";
import media from "styled-media-query";

export const ContainerGeneric = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const HeaderContainer = styled(ContainerGeneric)`
  gap: 20px;
  width: 100%;
  height: 130px;
  padding: 5px 0px;
  align-items: center;
  background: #0f1124;
  transition: all 400ms;
  justify-content: space-around;
  border-bottom: 2px solid #8affff;
  ${media.lessThan("large")`
    padding: 10px;
    justify-content: space-between;
  `}
`;
export const Img = styled.img<{ small?: boolean }>`
  max-width:${({ small }) => (small ? "100px" : "180px")};

  ${media.lessThan("large")`
    width: 30vw;
  `}
`;
export const Icon = styled.img`
  max-width: 60px;
  ${media.greaterThan("large")`
    display: none;
  `}
  ${media.lessThan("large")`
    width: 30vw;
  `}
`;
export const ContainerLinks = styled(ContainerGeneric)<{ open?: boolean }>`
  a {
    padding: 0 40px;
    position: sticky;
    text-align: center;
    text-decoration: none;
    :after {
      left: 0;
      top: 30px;
      width: 100%;
      content: "";
      display: flex;
      position: absolute;
      border-bottom: 4px solid transparent;
      transition: border-bottom 0.4s ease-out;
    }
    :hover {
      :after {
        border-color: #e696e9;
      }
    }
  }
  ${media.lessThan("large")`
    top: 0;
    gap: 40px;
    left: -100%;
    width: 100%;
    height: 100vh;
    position: fixed;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #101123;
    transition: all 0.4s ease-out;
    a {
      p{
        font-size: 2.9rem;
      }
    }
    `}
  ${({ open }) =>
    open &&
    css`
      left: 0 !important;
    `}
`;
export const HeaderLinks = styled(ContainerGeneric)`
  width: 100%;
  padding: 10px 40px;
  justify-content: space-between;
  border-bottom: 2px solid #8affff;
  ${media.greaterThan("large")`
    display: none;
  `}
`;
export const Text = styled.p`
  gap: 5px;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.9rem;
  font-family: "Poppins";
`;
export const Link = styled.a`
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 2px;
  text-decoration: none;
  font-family: "Poppins", Sans-serif;
`;
