import styled, { css } from "styled-components";
import media from "styled-media-query";
import { styleProps } from "./@types";

const GenericContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  /* > img:last-child {
    margin: 0 auto;
  } */
`;
export const Container = styled(GenericContainer)<styleProps>`
  gap: 60px;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
  ${({ start }) =>
    start &&
    css`
      align-items: flex-start;
    `}
  ${({ size }) =>
    size &&
    css`
      max-width: ${size};
      margin: 0 auto;
    `}
`;
export const HomeContainer = styled(GenericContainer)`
  gap: 20px;
  flex-direction: column;
`;
export const ContainerText = styled.div<styleProps>`
  gap: 10px;
  height: 100%;
  display: flex;
  max-width: 43%;
  align-items: start;
  flex-direction: column;
  justify-content: start;

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `}
  ${media.lessThan("medium")`
    max-width: 95%;
  `}
`;
export const Title = styled.h2<styleProps>`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  align-items: center;
  font-family: "Poppins";
  font-size: ${({ size }) => size || "2"}rem;
  font-weight: ${({ light }) => (light ? "400" : "600")};
  color: ${({ color }) => color || "#ffeeff"};

  ${({ center }) =>
    center &&
    css`
      margin: 0 auto;
    `}
`;
export const Text = styled.p`
  font-size: 2rem;
  color: #ffffff;
  font-weight: 400;
  font-family: "Poppins";
`;
export const Span = styled.span`
  cursor: pointer;
  color: #c636c6;
  font-weight: 400;
  font-size: 1.7rem;
  word-break: break-all;
  font-family: "Poppins";
`;
export const Img = styled.img<styleProps>`
  border-radius: 5px;
  max-width: ${({ size }) => size || "auto"};
  ${({ center }) =>
    center &&
    css`
      margin: 0 auto;
    `}
  ${media.lessThan("medium")`
    width: 100%;
  `}
`;
export const Link = styled.a`
  color: inherit;
  text-decoration: none;
  img {
    border-radius: 55px;
  }
`;
