import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import images, { getImage } from "../../assets/img";
import * as S from "./HomeStyled";

export const Home = () => {
  const [contract] = useState("0xb63ad3ce4120c4a0cf6f90e5410d3fbc31b69e26");
  const [imgActive, setImgActive] = useState(0);
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    const linkRef = contract;
    const el = document.createElement("textarea");
    el.value = linkRef;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let index = imgActive;
      if (index === 4) {
        setImgActive(0);
      } else {
        setImgActive((index += 1));
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [imgActive]);
  return (
    <S.HomeContainer>
      <S.Container start size="80%">
        <S.ContainerText>
          <Fade delay={1e3} damping={1e-1} direction="left">
            <S.Title size="3.5" color="purple">
              Everything
            </S.Title>
            <S.Title size="3.5" color="purple">
              Started
            </S.Title>
            <S.Text>
              Around 2075, the government lost control of the world power and
              became a tool to serve the consortium The whole world is shrouded
              in the haze of the consortium's rule, and human's clothing, food,
              housing and transportation are under the thorough control of them.
            </S.Text>
            <S.Title>
              Cyber Pirates <S.Text>Contract:</S.Text>
              <S.Span onClick={() => copyToClipboard()}>
                {isCopied ? "Copied" : contract}
              </S.Span>
            </S.Title>
          </Fade>
        </S.ContainerText>

        <Fade delay={1e3} damping={1e-1} direction="right">
          <S.Img
            size="500px"
            src={getImage(`wp${imgActive}`)}
            alt="Logo cyber pirates"
          />
        </Fade>
      </S.Container>
      <S.Container>
        <Fade delay={1e3} damping={1e-1} direction="left">
          <S.Img width="490px" src={images.holders} alt="Logo cyber pirates" />
        </Fade>
        <S.ContainerText>
          <Fade delay={1e3} damping={1e-1} direction="right">
            <S.Title size="3.5">Cyber Ship</S.Title>
            <S.Text>
              With the continuous efforts of the Cyber Pirates, they have
              discovered the loopholes of many consortia in the network, and
              each pirate also needs to complete their work and life in the real
              world. The organization has created an AI tool called Cybership.
              All members who hold CPT can rent and use this AI tool. It will
              automatically find the loopholes of the consortia in the network
              and cover up your funds into their transactions, So as to gain
              profit withdrawal. Pirates after discover a Loophole in the
              Consortium Atlas network and settle an AI tool called Cybership.
            </S.Text>
            <S.Text>
              Cybership is the Watershed between the new defi on the market.
              With his unique mechanism that allow an AI to operate into the
              market as a bot, using arbitrage, AMM, MEV and others system to
              reward their users which lock an amount of token into Cybership
              pool. Through this, you will be able to win between 3% up to 5%
              daily as a passive income.
            </S.Text>
          </Fade>
        </S.ContainerText>
      </S.Container>

      <S.Container>
        <Fade delay={1e3} damping={1e-1} direction="left">
          <S.Img
            width="490px"
            src={images.lpLogo}
            alt="Logo cyber pirates 2077"
          />
        </Fade>
        <S.ContainerText center>
          <Fade delay={1e3} damping={1e-1} direction="right">
            <S.Title size="3.5">Pirate Fund</S.Title>
            <S.Text>
              Due to the value deviation when CPT is used as a currency, cyber
              pirates bind it with the federal currency to maintain the value of
              CPT. The pirates spontaneously formed the LP of CPT. CPT LP award
              In order to reward all members who inject LP after injecting
              initial LP, Cyber Pirates added a 2% LP reward to the CPT
              transaction tax.
            </S.Text>
          </Fade>
        </S.ContainerText>
      </S.Container>
      <S.Img center size="500px" src={images.part2} alt="demo image" />
      <S.Title center size="4.8">
        The future belong to us
      </S.Title>
      <S.Container>
        <S.Img size="585px" src={images.wp13} alt="Logo cyber pirates" />
        <S.ContainerText>
          <S.Text>
            The original cyber pirates were composed of eight people. At first,
            they wandered around all corners of the cyber world. When they found
            that the consortia were constantly strengthening their conspiracy to
            rule mankind, they made a great decision and decided to use their
            life to fight against them.
          </S.Text>
          <S.Text>
            The supply of weapons to fight came through the Dark web, the only
            one market remaning out of the vision of the dictatorial and
            censorship consortium. Scattered over the world, people used to give
            their lifes in exchange of freedom.
          </S.Text>
        </S.ContainerText>
      </S.Container>
      <S.Title center size="6.0" color="#E696E9">
        <S.Link href="https://t.me/CyberPiratestoken" target="_blank">
          Contact us to become '0'
        </S.Link>
      </S.Title>
      <S.Title light center size="4.0" color="#E696E9">
        coming soon
      </S.Title>
      <S.Container>
        <Fade delay={1e3} cascade damping={1e-1}>
          <S.Link href="https://app.uniswap.org/#/swap" target="_blank">
            <S.Img size="81px" src={images.uniswap} alt="Logo uniswap" />
          </S.Link>
          <S.Link href="https://poocoin.app" target="_blank">
            <S.Img size="81px" src={images.poocoin} alt="Logo poocoin" />
          </S.Link>

          <S.Img size="81px" src={images.logoPatrocinado2} alt="Logo" />

          <S.Img size="81px" src={images.logoPatrocinado3} alt="Logo" />

          <S.Img size="81px" src={images.logoPatrocinado1} alt="Logo" />
          <S.Link href="https://www.dextools.io/app/en/bnb" target="_blank">
            <S.Img size="81px" src={images.dextools} alt="Logo" />
          </S.Link>
          <S.Link href="https://www.dexview.com/bsc" target="_blank">
            <S.Img size="81px" src={images.dexview} alt="Logo" />
          </S.Link>
        </Fade>
      </S.Container>
    </S.HomeContainer>
  );
};
