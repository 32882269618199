import styled, { css } from "styled-components";
import media from "styled-media-query";

export const LayoutContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  max-width: 100%;
  align-items: start;
  justify-content: space-between;
`;

export const LayoutContent = styled.div<{ position?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  > div:first-child {
    z-index: 2;
    width: 100%;
    ${({ position }) =>
      position &&
      css`
        top: 0;
        position: fixed;
      `}
  }

  ${media.lessThan("medium")`
    overflow-x: hidden;
  `}
`;

export const Container = styled.div<{ secundary?: boolean }>`
  width: 100vw;
  height: auto;
  display: flex;
  max-width: 100%;
  align-items: start;
  flex-direction: column;
  justify-content: flex-start;

  .children {
    width: 100%;
  }

  ${media.greaterThan("medium")`
    overflow-x: hidden;
  `}
`;
export const MobileWip = styled.div`
  width: 100vw;
  height: 100vh;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${media.lessThan("large")`
    display: flex;
  `}
`;
export const Img = styled.img``;
export const Text = styled.h2`
  max-width: 70%;
  color: #ffffff;
  font-weight: 100;
  margin-top: 10px;
  font-size: 1.8rem;
  text-align: center;
  font-family: "Supersonic Rocketship";
`;
