// import { Fade } from 'react-awesome-reveal';
import { useState, useEffect } from 'react';
import { Header, Footer } from '..';
import { GlobalStyles } from '../../../styles/globalStyled';
import { LayoutProps } from './@types';
import * as S from './LayoutStyled';

const Layout = ({ children }: LayoutProps) => {
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    const onScroll = (e: any) => {
      setScrollValue(e.target.documentElement.scrollTop);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollValue]);
  console.log("scrollValue ::", scrollValue);
  
  return (
    <S.LayoutContainer>
      <GlobalStyles />
      <S.LayoutContent position={scrollValue > 0}>
        <Header />
        <S.Container>
          {children}
        </S.Container>
        <Footer />
      </S.LayoutContent>
    </S.LayoutContainer>
  );
};

export default Layout;
