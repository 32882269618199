import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import images from "../../../assets/img";
import * as S from "./HeaderStyled";

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <Fade direction="down">
      <S.HeaderContainer>
        <S.Icon
          src={images.menu}
          alt="Icon menu"
          onClick={() => setOpen(true)}
        />
        <S.Img small src={images.logo} alt="Logo cyber pirates" />
        <S.ContainerLinks open={open}>
          <S.HeaderLinks>
            <S.Icon
              onClick={() => setOpen(!open)}
              src={images.close}
              alt="Icon close"
            />
            <S.Img small src={images.logo} alt="Logo cyber pirates" />
          </S.HeaderLinks>
          <S.Link
            href="https://cyberpiratetoken.gitbook.io/cyber-pirate-token-cpt/tokenomics"
            target="_blank"
          >
            <S.Text>TOKENOMICS</S.Text>
          </S.Link>
          <S.Link
            href="https://cyberpiratetoken.gitbook.io/cyber-pirate-token-cpt/cyber-roadmap"
            target="_blank"
          >
            <S.Text>ROADMAP</S.Text>
          </S.Link>
          <Link to="/">
            <S.Text>CYBER SHIP</S.Text>
          </Link>
          <Link to="/">
            <S.Text>CYBERRPOOL</S.Text>
          </Link>
          <S.Link
            href="https://cyberpiratetoken.gitbook.io/cyber-pirate-token-cpt/cpt-pool"
            target="_blank"
          >
            <S.Text>LP EARN</S.Text>
          </S.Link>
          <S.Link
            href="https://cyberpiratetoken.gitbook.io/cyber-pirate-token-cpt/"
            target="_blank"
          >
            <S.Text>WHITEPAPER</S.Text>
          </S.Link>
        </S.ContainerLinks>
      </S.HeaderContainer>
    </Fade>
  );
};

export default Header;
