import { BrowserRouter } from "react-router-dom";
import { Layout } from "./components/modules";
import { AppRoutes } from "./routes";

export const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <AppRoutes />
      </Layout>
    </BrowserRouter>
  );
}