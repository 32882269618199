import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { styleProps } from './@types';

export const ContainerGeneric = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const FooterContainer = styled(ContainerGeneric)`
  gap: 20px;
  width: 100%;
  height: auto;
  padding: 5px 0px;
  min-height: 210px;
  align-items: center;
  background: #0F1124;
  transition: all 400ms;
  justify-content: space-around;
  border-top: 2px solid #8AFFFF;
  
  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;
export const Img = styled.img`
  max-width: 180px;
`;
export const ContainerLinks = styled(ContainerGeneric)<styleProps>`
  gap: 2px;
  flex-direction: column;
  a {
    text-decoration: none;
  }

  ${({ end }) => end && css`
    align-items: flex-end;
  `}

`;
export const Text = styled.p<styleProps>`
  gap: 5px;
  color: #ffffff;
  font-size: 1.6rem;
  font-family: 'Poppins';
  font-weight: ${({ light }) => light ? '400' : '600'};
`;
export const Link = styled.a`
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 2px;
  text-decoration: none;
  font-family: "Poppins", Sans-serif;
`;
export const Button = styled.button`
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  padding: 12px 24px;
  border-radius: 2px;
  background-color: #E696E9;
  font-family: "Poppins", Sans-serif;
  :hover {
    background-color: #B165B4;
  }
  a {
    p{
      color: #131327;
    }
  }
`;